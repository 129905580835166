<template>
  <v-container
    id="permission"
    fluid
    tag="section"
  >
    <v-row>
      <v-col>
        <base-material-card
          color="primary"
          icon="mdi-shield-check"
          inline
          class="px-5 py-3"
          hover-reveal
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light mt-2">
              Permission Configurations
            </div>
          </template>

          <v-row class="align-center">
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="4"
            >
              <v-autocomplete
                v-model="selectedApp"
                :items="apps"
                prepend-icon="mdi-application"
                label="Select App"
                @change="getUsersByApp(selectedApp)"
              />
            </v-col>
          </v-row>

          <v-row class="align-center">
            <!-- Disallowed Users -->
            <v-col
              cols="12"
              md="5"
            >
              <v-row class="px-4 justify-center">
                <span class="text-h4 font-weight-light">Disallowed Users</span>
              </v-row>

              <v-row class="px-4">
                <v-card
                  outlined
                  width="100%"
                >
                  <v-card-text>
                    <v-virtual-scroll
                      :items="disallowedUsers"
                      :item-height="50"
                      height="300"
                    >
                      <template v-slot="{item}">
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="item.value"
                              :color="item.value && 'primary' || 'grey'"
                              @change="selectUserToAllow(item)"
                            >
                              <template v-slot:label>
                                <div
                                  :class="item.value && 'primary--text' || 'grey--text'"
                                  class="ml-4"
                                  v-text="item.text"
                                />
                              </template>
                            </v-checkbox>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </v-card-text>
                </v-card>
              </v-row>

              <v-row class="px-4">
                <v-checkbox
                  v-model="allowAll"
                  class="px-2"
                  :color="allowAll && 'primary' || 'grey'"
                >
                  <template v-slot:label>
                    <div
                      :class="allowAll && 'primary--text' || 'grey--text'"
                      class="ml-4"
                    >
                      Select All Disallowed User
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
            </v-col>

            <!-- Buttons -->
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                width="100%"
                @click="allow"
              >
                Allow
                <v-icon right>
                  mdi-chevron-double-right
                </v-icon>
              </v-btn>

              <v-btn
                width="100%"
                @click="disallow"
              >
                <v-icon left>
                  mdi-chevron-double-left
                </v-icon>
                Disallow
              </v-btn>
            </v-col>

            <!-- Allowed Users -->
            <v-col
              cols="12"
              md="5"
            >
              <v-row class="px-4 justify-center">
                <span class="text-h4 font-weight-light">Allowed Users</span>
              </v-row>

              <v-row class="px-4">
                <v-card
                  outlined
                  width="100%"
                >
                  <v-card-text>
                    <v-virtual-scroll
                      :items="allowedUsers"
                      :item-height="50"
                      height="300"
                    >
                      <template v-slot="{item}">
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="item.value"
                              :color="item.value && 'grey' || 'primary'"
                              @change="selectUserToDisallow(item)"
                            >
                              <template v-slot:label>
                                <div
                                  :class="item.value && 'grey--text' || 'primary--text'"
                                  class="ml-4"
                                  v-text="item.text"
                                />
                              </template>
                            </v-checkbox>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-virtual-scroll>
                  </v-card-text>
                </v-card>
              </v-row>

              <v-row class="px-4">
                <v-checkbox
                  v-model="disallowAll"
                  class="px-2"
                  :color="disallowAll && 'grey' || 'primary'"
                >
                  <template v-slot:label>
                    <div
                      :class="disallowAll && 'grey--text' || 'primary--text'"
                      class="ml-4"
                    >
                      Select All Allowed Users
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'

  export default {
    name: 'Permissions',

    data: () => ({
      appsData: [],
      apps: [],
      selectedApp: '',

      allowedUsers: [],
      usersToDisallow: [],
      allowAll: false,

      disallowedUsers: [],
      usersToAllow: [],
      disallowAll: false,

      urls: {
        getApps: '/v1/api/apps',

        allow: '/v1/api/users/app/roles',
        disallow: '/v1/api/user/roles/remove', // /:username/:cid"

        getAllowedUsersByApp: '/v1/api/user/roles/allow',
        getDisallowedUsersByApp: '/v1/api/user/roles/disallow',
      },
    }),

    watch: {
      allowAll: function (n, o) {
        var item
        if (n) {
          for (item of this.disallowedUsers) {
            item.value = true
            this.selectUserToAllow(item)
          }
        } else {
          for (item of this.disallowedUsers) {
            item.value = false
          }
          this.usersToAllow = []
        }
      },

      disallowAll: function (n, o) {
        var item
        if (n) {
          for (item of this.allowedUsers) {
            item.value = true
            this.selectUserToDisallow(item)
          }
        } else {
          for (item of this.allowedUsers) {
            item.value = false
          }
          this.usersToDisallow = []
        }
      },
    },

    created () {
      this.getApps()
    },

    methods: {
      async getApps () {
        await axios.get(this.urls.getApps, { withCredentials: false })
          .then((response) => {
            // console.log('response', response)
            this.appsData = []
            if (response.data.data) {
              this.appsData = response.data.data
            }
            this.apps = this.appsData.map((item) => {
              return {
                text: `${item.appName} (${item.clientID})`,
                appName: item.appName,
                value: item.clientID,
                permissions: item.permissions,
              }
            })
          }).catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false))
      },

      getUsersByApp (app) {
        this.allowAll = false
        this.disallowAll = false
        this.allowedUsers = []
        this.disallowedUsers = []
        if (app === null || app === undefined || app === '') return
        this.getAllowedUsersByApp(app)
        this.getDisallowedUsersByApp(app)
      },

      async getAllowedUsersByApp (app) {
        await axios.get(`${this.urls.getAllowedUsersByApp}/${app}`, { withCredentials: false })
          .then((response) => {
            // console.log('response', response)
            if (response.data.data) {
              this.allowedUsers = response.data.data.map((item) => {
                return {
                  text: item.username,
                  value: false,
                }
              })
            }
          }).catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false))
      },

      async getDisallowedUsersByApp (app) {
        await axios.get(`${this.urls.getDisallowedUsersByApp}/${app}`, { withCredentials: false })
          .then((response) => {
            // console.log('response', response)
            if (response.data.data) {
              this.disallowedUsers = response.data.data.map((item) => {
                return {
                  text: item.username,
                  value: false,
                }
              })
            }
          }).catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false))
      },

      selectUserToAllow (item) {
        const index = this.usersToAllow.indexOf(item)
        // console.log('index', index, item)
        if (index >= 0) {
          this.usersToAllow.splice(index, 1)
        } else {
          this.usersToAllow.push(item)
        }
        // console.log('usersToAllow', this.usersToAllow)
      },

      selectUserToDisallow (item) {
        const index = this.usersToDisallow.indexOf(item)
        if (index >= 0) {
          this.usersToDisallow.splice(index, 1)
        } else {
          this.usersToDisallow.push(item)
        }
      },

      async allow () {
        if (this.selectedApp === '' ||
          this.selectedApp === undefined ||
          this.usersToAllow.length === 0) return
        // console.log('selectedApp', this.selectedApp)
        // console.log('this.usersToAllow', this.usersToAllow)
        const usernames = this.usersToAllow.map((item) => { return item.text })
        const data = {
          clientID: this.selectedApp,
          username: usernames,
        }
        await axios.post(this.urls.allow, data)
          .then((response) => {
            // console.log('response', response)
            this.$swal.fire({
              titleText: `User ${usernames} can use app now`,
              icon: 'success',
              showCancelButton: false,
            })

            const users = this.usersToAllow
            for (var i in users) {
              // console.log('i', i, users[i])
              const item = {
                text: users[i].text,
                value: false,
              }
              // console.log('item', item)

              this.allowedUsers.push(item)
              const index = this.disallowedUsers.indexOf(users[i])
              // console.log('index', index, this.disallowedUsers)

              this.disallowedUsers.splice(index, 1)
            }
            this.usersToAllow = []
            this.allowAll = false
            // console.log('load', this.users)
          }).catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false))
      },

      async disallow () {
        if (this.selectedApp === '' ||
          this.selectedApp === undefined ||
          this.usersToDisallow.length === 0) return
        const usernames = this.usersToDisallow.map((item) => { return item.text })
        const data = {
          clientID: this.selectedApp,
          username: usernames,
        }
        await axios.post(this.urls.disallow, data)
          .then((response) => {
            // console.log('response', response)
            this.$swal.fire({
              titleText: `User ${usernames} cannot use app now`,
              icon: 'success',
              showCancelButton: false,
            })

            const users = this.usersToDisallow
            for (var i in users) {
              // console.log('i', i, users[i])
              const item = {
                text: users[i].text,
                value: false,
              }
              // console.log('item', item)

              this.disallowedUsers.push(item)
              const index = this.allowedUsers.indexOf(users[i])
              // console.log('index', index, this.allowedUsers)
              this.allowedUsers.splice(index, 1)
            }
            this.usersToDisallow = []
            this.disallowAll = false
            // console.log('load', this.users)
          }).catch(e => {
            this.handleErrors(e)
          }).finally(() => (this.loading = false))
      },

      handleErrors (error) {
        if (error.response === undefined) {
          this.show_message('error', error.toString())
        } else if (!error.response.data.success) {
          if (error.response.data.errorMessage) {
            this.show_message('error', error.response.data.errorMessage)
          } else if (error.response.data.validations) {
            this.show_message('error', error.response.data.validations[0].message)
          } else {
            this.show_message('error', error.response.data.message)
          }
        } else {
          this.show_message('error', 'api service not available!')
        }
      },

      show_message (type, text) {
        // type = 'success', 'info', 'error' ... color string
        this.$swal.fire({
          icon: type,
          titleText: type,
          text: text,
          timer: 5000,
        })
      },
    },
  }
</script>
